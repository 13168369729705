import React from 'react'

const componentName = () => {
    return (
<div className="socialmedia">
    <div className="icon-bar">
        <a href="https://www.facebook.com/contactsbv" target="_blank" rel="noopener noreferrer" className="facebook">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="8 -1 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook">
                <path d="M18 2h-3a4 4 0 0 0-4 4v3H9v4h2v8h4v-8h3.6l.4-4H15V6a1 1 0 0 1 1-1h2z"></path>
            </svg>
        </a>
    </div>
</div>

    )
}

export default componentName