import React from "react";
import carte from '../img/carte.png';


export default function SimpleMap(){


  return (
    // Important! Always set the container height explicitly
    <div style={{ marginLeft: '5%', height: '400px', width: '600px' }}>
      <img src={carte} alt="Emplacement sur la carte" style={{width: '100%', height: '100%'}}/>
    </div>
  );
}