import React from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const GesForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Message envoyé !', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='BilangesForm'>
        <p>Bilan GES | Le formulaire</p>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                {/* Row 1 of form */}
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nom de l'entreprise / organisation"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer votre nom' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Votre Nom'
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                                        <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer votre fonction' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Votre Fonction'
                      autoComplete="off"
                    ></input>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email Professionnel'
                      autoComplete="off"
                    ></input>
                </div>
                {/*Hide error Row */}
                <div className="row">
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        {errors.email && (
                        <span className='errorMessageMail'>Veuillez entrer une adresse email valide</span>
                        )}
                </div>
                {/* Row 2 of form */}
                <div className='row'>
                <label>CONSOMMATIONS D'ÉNERGIE DES LOCAUX</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Electricité consommé sur 1 an en kWh"
                      autoComplete="off"
                    ></input>
                                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Consommation du Réseau froid en kWh"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                <label>DÉPLACEMENTS DES COLLABORATEURS</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Consommation totale d'essence par mois en Litre"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Consommation totale de gasoil par mois en Litre"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                <label>DEPLACEMENT AERIENS DES COLLABORATEURS</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de vol inférieur à 3000 km"
                      autoComplete="off"
                    ></input>
                                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de vol entre 3000 et 6000 km"
                      autoComplete="off"
                    ></input>
                                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de vol supérieur à 3000 km"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                <label>DONNEES RELATIVE AU TRANSPORT DE MARCHANDISES</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Fret Routier : nombre de litre de carburant consommé"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Fret Aerien distance inférieure à 3000 tonne.km"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Fret Aerien distance supérieure à 3000 tonne.km"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Fret Maritime distance en tonne.km"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Fret Fluvial distance en tonne.km"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                <label>CONSOMMABLES</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Poids total de papier consommé en tonnes"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Nombre de ramette 500 pages A4 consommé par an"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Budjet petites fournitures en FCFA"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Budjet informatique et bureautique en FCFA"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                <label>IMMOBILISATIONS</label>
                </div>
                <div className='row'>
                <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="le batiment est-il supérieur ou inférieur à 20 ans"
                      autoComplete="off"
                    ></input>
                                       <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="Type d'activité"
                      autoComplete="off"
                    ></input>
                </div>
                <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de véhicules de fonction légers"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de véhicules de fonction utilitaire"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de véhicules de fonction lourds"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de postes fixes"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre d'ordinateurs portables"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre d'imprimantes individuelles"
                      autoComplete="off"
                    ></input>
                                        <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre d'imprimantes multifonctions"
                      autoComplete="off"
                    ></input>
                    </div>
                    <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de serveurs"
                      autoComplete="off"
                    ></input>
                                        <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre de photocopieuses"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre d'écrans plats"
                      autoComplete="off"
                    ></input>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer le nom de votre entreprise' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder="nombre d'unités centrales"
                      autoComplete="off"
                    ></input>

                </div>
                
                {/*Hide error Row */}
                <div className="row">
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                </div>
                <div className="container-contact100-form-btn">
                    <button className='contact100-form-btn' type='submit'>
                    <span>
                        Envoyer
                        <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                    </span>
                    </button>
                </div>
              </form>
            <ToastContainer />
            {console.log(process.env.REACT_APP_TEMPLATE_ID)}
    </div>
  );
};

export default GesForm;