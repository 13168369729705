// src/components/CircularLoader.js
import React from 'react';

const CircularLoader = ({ progress }) => {
  return (
    <div className="circular-loader">
    <h1 style={{ textAlign: 'center' }}>Inspirer Co-construire Accompagner</h1>
    <svg className="progress-circle" width="150" height="150">
  {/* Cercle de fond */}
  <circle className="progress-background" cx="75" cy="75" r="65" />
  
  {/* Barre de progression */}
  <circle
    className="progress-bar"
    cx="75"
    cy="75"
    r="65"
    style={{
      strokeDasharray: 2 * Math.PI * 65,
      strokeDashoffset: 2 * Math.PI * 65 * ((100 - progress) / 100)
    }}
  />
  
  {/* Spinner rotatif */}
  <circle
    className="spinner"
    cx="75"
    cy="75"
    r="65"
    style={{
      strokeDasharray: 2 * Math.PI * 65,
      strokeDashoffset: 2 * Math.PI * 65 * 0.25, // Un segment qui tourne
      transform: "rotate(-90deg)",
      transformOrigin: "center",
      animation: "spin 1.5s linear infinite"
    }}
  />

  {/* Texte du pourcentage */}
  <text x="75" y="85" textAnchor="middle" fontSize="1.5rem" fill="#333">
    {progress}%
  </text>
</svg>



    </div>
  );
};

export default CircularLoader;
