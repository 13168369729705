import React from 'react';
import { saveAs } from "file-saver";

const PdfDownloader = ({ pdf, buttonText, fileName }) => {
    const saveFile = () => {
        saveAs(pdf, fileName); // Télécharge le fichier avec le nom spécifié
    }

    return (
        <div>
            <button className='pdfDownloadbtn' onClick={saveFile}>{buttonText}</button>
        </div>
    );
}

export default PdfDownloader;
