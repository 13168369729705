// src/App.js
import React, { useState, useEffect } from "react";
import CircularLoader from "./components/CircularLoader";
import MainContent from "./components/MainContent"; // Remplacez par le contenu principal de votre site

import "./App.css";
import "./Carousel.scss";
import "./Bilanges.scss";
import "./CircularLoader.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simule un délai pour le chargement en augmentant progressivement la progression
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          setLoading(false); // Une fois à 100%, le chargement est terminé
          return 100;
        }
        return oldProgress + 2; // Incrémente de 1%
      });
    }, 50); // Temps d'incrémentation du pourcentage

    // Nettoyage du timer
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {loading ? (
        <CircularLoader progress={progress} />
      ) : (
        <>
          <MainContent />
        </>
      )}
    </>
  );
}

export default App;
