import styled from "styled-components";

const SectionContainer = styled.section`
  min-height: 600px;
  min-width: 320px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 2em;
`;

export default SectionContainer;
