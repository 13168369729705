import React from 'react';
import styled from "styled-components";
import { SectionLink } from "react-scroll-section";

const Menu = ({ children }) => {
  return (
    <ul className="nav navbar-nav">
      {children}
    </ul>
  );
};

const Item = styled.li`
  cursor: pointer;
  border-top: ${(props) =>
    props.selected ? "#046D35" : "transparent"} 3px solid;
`;

function MenuItem({ children, section }) {
  const handleMenuClick = (link) => {
    if (link.onClick) {
      // Appeler le onClick fourni par react-scroll-section
      link.onClick();

      // Désactiver temporairement le scroll magnétique
      // Cela permettra au lien de fonctionner normalement
      if (link.scroll) {
        link.scroll({ behavior: "smooth" });

        // Assure que l'effet magnétique est désactivé temporairement
        if (typeof window.disableScrollMagnetic === "function") {
          window.disableScrollMagnetic();
        }
      }
    }
  };

  return (
    <SectionLink section={section}>
      {(link) => (
        <Item onClick={() => handleMenuClick(link)} selected={link.isSelected}>
          {children}
        </Item>
      )}
    </SectionLink>
  );
}

export { MenuItem as Item, Menu };
