import React from "react";
import Header from "./Header";
import Logo from "./Logo";
import { Menu, Item } from "./Menu";
import { ScrollingProvider, Section } from "react-scroll-section";
import SectionContainer from "./SectionContainer";
import ExpertiseContainer from "./ExpertiseContainer";
import Slider from "./Slider";
import clients from "../img/clients_rect.png";
import clients_resize from "../img/clients_carre.png";
import Expertise from "./Expertise";
import Contact from "./Contact";
import Carousel3d from "./Carousel3d";
import SocialMediaIcon from "./SocialMediaIcon";
import Footer from "./Footer";

// Importer les images nécessaires
import study from "../img/study2.jpg";
import handshake_lobby from "../img/handshake_lobby.jpg";
import formation from "../img/formation.jpg";
import communication from "../img/communication.png";

const MainContent = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ScrollingProvider>
      <div className="main-content">
        <div className="container-sm">
          <Header
            logo={<Logo />}
            menu={
              <Menu>
                <Item section="expertises">
                  <a
                    href="#expertises"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("expertises");
                    }}
                  >
                    Expertises
                  </a>
                </Item>
                <Item section="realisations">
                  <a
                    href="#realisations"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("realisations");
                    }}
                  >
                    Réalisations
                  </a>
                </Item>
                <Item section="clients">
                  <a
                    href="#clients"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("clients");
                    }}
                  >
                    Clients
                  </a>
                </Item>
                <Item section="contact">
                  <a
                    href="#contact"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("contact");
                    }}
                  >
                    Contact
                  </a>
                </Item>
              </Menu>
            }
          />
          <div className="row">
            <Section className="section" id="slider">
              <SectionContainer>
                <Slider />
              </SectionContainer>
            </Section>
          </div>

          <SocialMediaIcon />

          <Section className="section" id="expertises">
            <div className="row exp">
              <SectionContainer>
                <ExpertiseContainer>
                  <div className="about">
                    <h3>À PROPOS DE SBV CONSULTING</h3>
                    <p>
                      SBV CONSULTING est, au Congo Brazzaville, un acteur en
                      Audit / Consulting dans les domaines de l'énergie et de
                      l'environnement.
                    </p>
                  </div>
                  <Expertise image={study} title="ÉTUDES DIVERSES">
                    <li>
                      Analyse, diagnostic et <b>rédaction de préconisations</b>{" "}
                      dans les domaines de{" "}
                      <a href="#realisations">
                        <strong className="important">l'énergie</strong>
                      </a>
                      , des mines et de l'industrie.
                    </li>
                    <li>
                      Nous réalisons des <strong>études d'impact</strong>{" "}
                      environnemental et social.
                    </li>
                  </Expertise>
                  <Expertise image={formation} title="FORMATION">
                    <p>Nous assurons trois types de formation :</p>
                    <li>
                      L'accompagnement en responsabilité sociétale des
                      entreprises (<b>RSE</b>).
                    </li>
                    <li>
                      Sensibilisation aux normes <b>QHSE</b> : Qualité, Hygiène,
                      Sécurité, Environnement.
                    </li>
                    <li>
                      <b>Coaching & Développement Personnel</b> : Atteignez vos
                      objectifs.
                    </li>
                  </Expertise>
                  <Expertise
                    image={handshake_lobby}
                    title="LOBBYING, ASSISTANCE AUX ENTREPRISES"
                  >
                    <li>
                      Nous vous accompagnons dans vos{" "}
                      <b>démarches administratives</b> en jouant un rôle de{" "}
                      <a href="#contact">
                        <span className="important">facilitateur</span>
                      </a>{" "}
                      auprès des administrations locales.
                    </li>
                  </Expertise>
                  <Expertise image={communication} title="COMMUNICATION">
                    <li>Communication d'entreprise et d'institution.</li>
                    <li>Culture et patrimoine.</li>
                    <a href="#realisations">
                      <strong className="important">
                        <li>Conférence à thème.</li>
                      </strong>
                    </a>
                  </Expertise>
                </ExpertiseContainer>
              </SectionContainer>
            </div>
          </Section>

          <Section className="section" id="realisations">
            <div className="row">
              <SectionContainer>
                <div className="carousel">
                  <div className="col-12">
                    <Carousel3d />
                  </div>
                </div>
              </SectionContainer>
            </div>
          </Section>

          <Section className="section" id="clients">
            <SectionContainer>
              <picture>
                <source media="(max-width: 500px)" srcSet={clients_resize} />
                <img className="clients" src={clients} alt="nos clients" />
              </picture>
            </SectionContainer>
          </Section>

          <Section className="section" id="contact">
            <SectionContainer>
              <Contact />
            </SectionContainer>
          </Section>
          <Footer />
        </div>
      </div>
    </ScrollingProvider>
  );
};

export default MainContent;
