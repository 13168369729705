import React from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Message envoyé !', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='ContactForm'>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                {/* Row 1 of form */}
                <div className='row'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Veuillez entrer votre nom' },
                        maxLength: {
                          value: 30,
                          message: 'Veuillez utiliser 30 characters maximum'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Nom'
                      autoComplete="off"
                    ></input>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email'
                      autoComplete="off"
                    ></input>
                </div>
                {/*Hide error Row */}
                <div className="row">
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        {errors.email && (
                        <span className='errorMessageMail'>Veuillez entrer une adresse email valide</span>
                        )}
                </div>
                {/* Row 2 of form */}
                <div className='row'>
                    <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: { value: true, message: 'Veuillez entrer l\'objet de votre message' },
                        maxLength: {
                          value: 75,
                          message: 'L\'objet du message ne peut pas dépasser 75 characters'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Objet'
                      autoComplete="off"
                    ></input>
                </div>
                {/*Hide error Row */}
                <div className="row">
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                </div>
                {/* Row 3 of form */}
                <div className='row'>
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className='form-control'
                      placeholder='Message'
                    ></textarea>
                </div>
                {/*Hide error Row */}
                <div className="row">
                    {errors.message && <span className='errorMessage'>Veuillez entrer un message</span>}
                </div>
                <div className="container-contact100-form-btn">
                    <button className='contact100-form-btn' type='submit'>
                    <span>
                        Envoyer
                        <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                    </span>
                    </button>
                </div>
              </form>
            <ToastContainer />
            {console.log(process.env.REACT_APP_TEMPLATE_ID)}
    </div>
  );
};

export default ContactForm;