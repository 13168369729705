import React from 'react'
import Logo from './Logo'

const NotFound = () => {
    return (
        <div className='notfound'>

            <Logo />
            <h3>Page non trouvée</h3>
            <p>La page que vous cherchez n'existe pas.</p>
            {/*link to home page*/}
            <a href='/'>Retour à l'accueil</a>

        </div>
    )
}

export default NotFound