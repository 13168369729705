import React from "react";
import nature from "../img/nature7.jpg";
import mission from "../img/mission.jpg";
import earthatnight from "../img/earthatnight-lum0.jpg";

// JSX
import HeroSlider, { Slide, MenuNav, OverlayContainer } from "hero-slider";
import Navbar from "./UI/Navbar/Navbar";
import Wrapper from "./UI/Wrapper/Wrapper";
import Title from "./UI/Title/Title";
import Subtitle from "./UI/Subtitle/Subtitle";

// Images
const kyoto = mission;
const tenryuJiTemple = earthatnight;
const hakone = nature;

const Slider = () => {
  return (
    <HeroSlider
      slidingAnimation="top_to_bottom"
      orientation="vertical"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "#000",
      }}
      settings={{
        slidingDuration: 500,
        slidingDelay: 200,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 11000,
        height: "100vh",
      }}
    >
      <Navbar />

      <Slide
        shouldRenderMask
        navDescription="Conseil"
        background={{
          backgroundColor: "#6D9B98",
          backgroundImage: kyoto,
          backgroundAnimation: "fade",
          //5 seconds animation duration
          backgroundAnimationDuration: 7000,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            <>
              <h1>
                <>
                  <span className="Firstword">SBV</span> Consulting
                </>
              </h1>
            </>
            <Subtitle>Inspirer</Subtitle>
            <Subtitle>Co-constuire</Subtitle>
            <Subtitle>Accompagner</Subtitle>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        shouldRenderMask
        navDescription="Énergie"
        background={{
          backgroundColor: "#8A8A8A",
          backgroundImage: tenryuJiTemple,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            <Title>
              <>
                <span className="Firstword">L'Énergie Précède</span> le
                Développement
              </>
            </Title>
            <Subtitle>Hydrocarbures</Subtitle>
            <Subtitle>Solaire</Subtitle>
            <Subtitle>Biomasse</Subtitle>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        shouldRenderMask
        navDescription="Environnement"
        background={{
          backgroundColor: "#EA2329",
          backgroundImage: hakone,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            <Title>
              <>
                <span className="Firstword">Un Développement</span> Durable et
                Intégral
              </>
            </Title>
            <Subtitle>Économique</Subtitle>
            <Subtitle>Social</Subtitle>
            <Subtitle>Écologique</Subtitle>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <MenuNav />
    </HeroSlider>
  );
};

export default Slider;
