import React from 'react'

const Header = ({logo, menu}) => {
    return (
        <div className="navbar-wrapper">
            <div className="container-fluid">
                <nav className="navbar navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            </button>
                            <a className="homelink"  href="/"> 
                                { logo }
                            </a>
                        </div>
                        <div id="navbar" className="navbar-collapse collapse">
                                { menu }
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Header