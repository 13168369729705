import React from "react";
import FadeInImage from "./FadeInImage";

const Expertise = ({ image, title, children }) => {
  return (
    <div className="element">
      <div className="expImage">
        <FadeInImage src={image} alt="Expertises" />
      </div>
      <div className="expText">
        <h2>{title}</h2>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Expertise;
