import React, { Component } from 'react'
import SimpleMap from './MapGoogle'
import mapmarker from '../img/mapmarker.png'
import email_icon from '../img/email_icon.png'
import phone_icon from '../img/phone_icon.png'
import ContactForm from './ContactForm'


class Contact extends Component {
    
     render () {
        return (
        <div className="contact">
            <div className="contactTitle"><h2>Contactez-nous</h2></div>
            <div className="contactBody">
                <div className="map">
                    <SimpleMap/>
                </div>
                <div className="contactBodyright">
                        <div className="contactInfo">
                            <div><img className="icon" src={mapmarker} alt="Adresse"/>11 rue Mandou, Base Industrielle, Pointe-Noire</div>
                            <div><img className="icon" src={email_icon} alt="Mail"/>contact@sbv-consulting.cg</div>
                            <div><img className="icon" src={phone_icon} alt="Tel"/>+242 06 699 32 30</div>
                        </div>
                        <div className="contactFormFlex">
                            <ContactForm />
                        </div>
                </div>
                </div>
        </div>
        )
   }
}

export default Contact