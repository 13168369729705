import React from "react";
import { useRef, useEffect } from "react";
import hydrocarbure_conference from "../img/hydrocarbure_conference.jpg";
import energie_conf from "../img/sbv_afd.png";
import femmesausommet from "../img/femmesausommet.jpg";
import colloque_loango from "../img/colloque_loango.png";
import fail_conf from "../img/failure_conf.png";
import dgsbv_dgxoil from "../img/dgsbv_dgxoil.jpg";
import sbvconf2022 from "../img/sbvconf2022.jpg";
import PdfDownloader from "./PdfDownloader";
import sbvconference_digest_001 from "../pdf/sbvconference_digest_001.pdf";
import actes_colloque_loango_2019 from "../pdf/actes_colloque_loango_2019.pdf";
import cieh5 from "../img/cieh5_montage.png";
import arpce_internetsoutenable from "../img/arpce_internetsoutenable.png";

const slides = [
  {
    title: "Projet Internet Soutenable",
    subtitle: "Siege ARPCE, Brazzaville",
    description:
      "Le cabinet SBV Consulting accompagne l'Agence de régulation des postes et des communications électroniques (ARPCE) afin d’intégrer la dimension environnementale dans les secteurs régulés, dans une démarche alignée avec les objectifs de développement durable (ODD)",
    moredescription: "",
    details:
      "Le projet Internet Soutenable a abouti à la création d'un observatoire dédié au numérique responsable, consultable en ligne.",
    youtube: "",
    pdf: "",
    image: arpce_internetsoutenable,
  },
  {
    title:
      "Conférence Internationale et Exposition sur les Hydrocarbures au Congo 2022",
    subtitle: "Centre de conférence de Kintélé, Brazzaville",
    description:
      "SBV Consulting partenaire associé de la 5ème Conférence et Exposition sur les Hydrocarbures au Congo.",
    moredescription:
      "Thème - Transition énergétique au Congo : Opportunités et Défis",
    details: "Avec l'intervention du Directeur Général de SBV Consulting",
    youtube: "",
    pdf: "",
    image: cieh5,
  },
  {
    title: "Entreprendre & Innover au 21e Siècle",
    subtitle: "Pointe-Noire, Hotel Elais",
    description:
      "Contribuer à l’émergence d’un cadre favorable à la création et au développement d’entreprises viables et performantes en Afrique et au Congo, c’est le sens de cette conférence organisée le 23/02/2022",
    details:
      "Diplômé du MIT et de l’Université de Stanford aux États-Unis, Jordan McRae était l’invité spécial de la conférence.",
    youtube: "https://youtu.be/Mx13eSvPJ6Y ",
    pdf: sbvconference_digest_001,
    buttontext: "Télécharger la synthèse",
    filename: "sbvconference_digest_001.pdf",
    image: sbvconf2022,
  },
  {
    title: "CEMAC Business Energy Forum 2021",
    subtitle: "Brazzaville",
    description:
      "Réflexion interactive entre les parties prenantes du secteur de l'énergie dans la zone CEMAC",
    details:
      "Echange du Directeur Général de SBV Consulting avec Donald Fylla Directeur Général adjoint de X-OIL. En dessous, le Secrétaire Général de la SNPC Sébastien Poaty & la Chef du département communication Aphrodite Oddet.",
    youtube: "",
    pdf: "",
    image: dgsbv_dgxoil,
  },
  {
    title:
      "Conférence Internationale et Exposition sur les Hydrocarbures au Congo 2020",
    subtitle: "Kintélé Brazzaville",
    description: "4ème Conférence et Exposition sur les Hydrocarbures au Congo",
    details:
      "Avec l'intervention à gauche sur la photo de Serge BOUITI-VIAUDO Directeur Général de SBV Consulting",
    youtube: "",
    pdf: "",
    image: hydrocarbure_conference,
  },
  {
    title: "Mission de consulting sur l'energie au Congo",
    subtitle: "SBV Consulting - Agence Française de Développement (AFD)",
    description:
      "Quel mix énergétique pour le développement du Congo à l'horizon 2030 ?",
    moredescription: "Les 4 « D » d’un mix énergétique résilient : ",
    list1: "Énergie Disponible (facilement accessible)",
    list2: "Énergie Distribuée (répartie sur le territoire)",
    list3: "Énergie Durable (respectueuse de l’environnement)",
    list4: "Énergie Diversifiée (provenant de plusieurs sources)",
    details:
      "Avec la présence d'André-Raphaël LOEMBA, ministre des hydrocarbures et Roland BOUITI-VIAUDO, deuxième vice-président du bureau de l’Assemblée nationale.",
    youtube: "",
    pdf: "",
    image: energie_conf,
  },
  {
    title: "Conférence Femmes au sommet",
    subtitle: "Pointe-Noire, Hôtel Azur",
    description: "SBV Consulting organise la conférence 'Femmes au sommet'",
    details:
      "La Responsabilité sociétale des entreprises (RSE) c'est aussi prendre en compte la diversité, focus sur les femmes. Avec la présence de Esther DEBOULET, Directrice Générale de Gestrim et Pierre JESSUA, Directeur Général de Total.",
    youtube: "",
    pdf: "",
    image: femmesausommet,
  },
  {
    title: "Colloque Loango 2019",
    subtitle: "Pointe-Noire, IFC",
    description: "SBV Consulting partenaire du Colloque Loango 2019",
    details:
      "Loango est une localité de la République du Congo, site majeur de déportation d'esclaves. L'ambition de ce colloque était de susciter une réflexion contemporaine à partir des connaissances sur les traites, la colonnisation et les sociétés post-esclavage.",
    ps: "Avec l'intervention de Catherine Coquery-Vidrovitch, historienne française",
    youtube: "",
    pdf: actes_colloque_loango_2019,
    buttontext: "Télécharger les actes",
    filename: "actes_colloque_loango_2019.pdf",
    image: colloque_loango,
  },
  {
    title: "Failure Conference",
    subtitle: "Pointe-Noire, Chambre de commerce",
    description: "SBV Consulting organise 'Failure Conference'",
    moredescription: "Fail often | Fail fast | Fail forward",
    details:
      "«J'ai raté 9000 tirs dans ma carrière.  26 fois, j'ai du prendre le tir de la victoire et j'ai raté. J'ai échoué encore et encore et encore dans ma vie. Et c'est pourquoi je réussis.» -Michael JORDAN",
    ps: "Aider les apprentis entrepreneurs à faire face à l'échec, c'est l'objectif de cette conférence.",
    youtube: "",
    pdf: "",
    image: fail_conf,
  },
];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0,
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length,
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1,
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  const refimage = useRef(null);

  useEffect(() => {
    // 👇️ use document.getElementById()
    const el = document.getElementById("my-element");
    console.log(el);

    // 👇️ (better) use a ref
    const el2 = refimage.current;
    console.log(el2);
  }, []);

  function knowMore(event) {
    // e.preventDefault();
    event.target.style.visibility = "hidden";
    const el2 = refimage.current;
    el2.classList.add("imageDown");
    //console.log('Le lien a été cliqué.');
  }

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      />
      <div className="grid">
        <figure className="effect-layla">
          <div className="slideContent" ref={refimage}>
            <img src={slide.image} alt="slideimage" />
            <button id="more-info" className="more" onClick={knowMore}>
              EN SAVOIR PLUS
            </button>
            <div className="slideDescInner">
              <h2 className="slideTitle">{slide.title}</h2>
              <h3 className="slideSubtitle">{slide.subtitle}</h3>
              <p className="slideDescription" style={{ textAlign: "center" }}>
                {slide.description}
              </p>
              <p className="slideDescription">{slide.moredescription}</p>
              <ul className="slideList">
                <li>{slide.list1}</li>
                <li>{slide.list2}</li>
                <li>{slide.list3}</li>
                <li>{slide.list4}</li>
              </ul>
              <p className="slideDetails">{slide.details}</p>
              <p className="slidePs">{slide.ps}</p>
              {slide.youtube === "" ? (
                <span className="fakebtn"></span>
              ) : (
                <a
                  href={slide.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="slideYtb"
                >
                  <p>Reportage vidéo</p>
                </a>
              )}
            </div>
            {slide.pdf === "" ? null : (
              <PdfDownloader
                pdf={slide.pdf}
                buttonText={slide.buttontext}
                fileName={slide.filename}
              />
            )}
          </div>
        </figure>
      </div>
    </div>
  );
}

const Carousel3d = () => {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);

  return (
    <div className="slides">
      <button
        className="nav-circlepop"
        onClick={() => dispatch({ type: "NEXT" })}
      >
        <a className="prev">
          <span className="prev icon-wrap"></span>
        </a>
      </button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <button
        className="nav-circlepop"
        onClick={() => dispatch({ type: "PREV" })}
      >
        <a className="next">
          <span className="next icon-wrap"></span>
        </a>
      </button>
    </div>
  );
};

export default Carousel3d;
