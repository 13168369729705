import styled from "styled-components";

const ExpertiseContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 2em;
`;

export default ExpertiseContainer;