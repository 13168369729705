import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="copyright">
                <span className="incognito"><a href="https://icons8.com" target="_blank" rel="noopener noreferrer">icons utilisés</a></span>
                © 2024 <b>SBV Consulting</b>. All Rights Reserved.
            </div>
        </footer>
    )
}

export default Footer