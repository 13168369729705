import React, { useState } from "react";
import "../FadeInImage.css";

const FadeInImage = ({ src, alt }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      className={`fade-in-image ${loaded ? "loaded" : ""}`}
      onLoad={() => setLoaded(true)}
    />
  );
};

export default FadeInImage;
